.page-heading {
    font-weight: 400;
    font-size: 1.7rem;
    margin-bottom: 0.2rem;
}

.table-heading {
    font-weight: 400;
    font-size: 0.9rem;
    margin-bottom: 0.4rem;
    color: #8798ad;
    text-transform: uppercase;
}

.table-data {
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 0.4rem;
}

.thumbnail {
    width: 4rem;
    height: auto;
}

.name {
    margin-left: 1.5rem
}

.highlight:hover {
    background-color: #f5f8fa;
}

.highlight-selected {
    background-color: #f5f8fa;
}

.dark-purple-button {
    color: #a952ff;
    background-color: rgba(169, 82, 255, 0.15);
    width: 6rem;
    height: 2rem;
    border-radius: 4px;
    font-weight: 400;
    border: 0px;
    font-size: 0.9rem;
    margin: 5px;
}

.dark-blue-button {
    color: rgb(57, 112, 179);
    background-color: rgba(57, 112, 179, 0.15);
    width: 6rem;
    height: 2rem;
    border-radius: 4px;
    font-weight: 400;
    border: 0px;
    font-size: 0.9rem;
    margin: 5px;
}

.light-purple-button {
    color: rgb(140, 82, 255);
    ;
    background-color: rgba(140, 82, 255, 0.15);
    width: 6rem;
    height: 2rem;
    border-radius: 4px;
    font-weight: 400;
    border: 0px;
    font-size: 0.9rem;
    margin: 5px;
}

.light-blue-button {
    color: rgb(82, 70, 179);
    background-color: rgba(82, 70, 179, 0.15);
    width: 6rem;
    height: 2rem;
    border-radius: 4px;
    font-weight: 400;
    border: 0px;
    font-size: 0.9rem;
    margin: 5px;
}

.upload-button {
    color: rgb(82, 161, 255);
    background-color: rgba(82, 161, 255, 0.15);
    width: 20rem;
    height: 4rem;
    border-radius: 2rem;
    font-weight: 500;
    border: 0px;
    font-size: 1rem;
    margin: 5px;
}

.table-border {
    border: 2px solid #e9ecef;
    border-bottom: none; /* Remove the border-bottom for all elements */
    padding: 1rem;
    margin: 0 0.2rem;
}

.table-border.table-border-last {
    border-bottom: 2px solid #e9ecef; /* Apply border-bottom only to the last element */
}


.mobile-table {
    gap: 5px;
    border-radius: 10px;
    /* box-shadow: 0px 0px 20px rgb(199, 199, 199); */
}

.mobile-box-1,
.mobile-box-2 {
    align-items: center;
    justify-content: start;
    width: 100%;
    gap: 10px;
}

.mobile-box-2 {
    justify-content: center;
}


.mobile-box-2 p {
    width: 100%;
    text-align: center;
    margin: 0;
}

/* .mobile-box-1 img {
    height: 100px;
} */

/* .mobile-name{
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
} */

.mobile-name {
    word-break: break-all;
}

.mobile-details {
    color: #b3b3b3;
}

.more-details-span {
    font-size: 12px;
    color: #a952ff;
    cursor: pointer;
}

.searchDiv {
    max-width: 30vw;
    width: 500px;
}

.searchDiv-input {
    padding: 4px 16px !important;
    height: unset;
    font-size: 0.8375vw;
    font-weight: bold;
    width: 100%;
    /* box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.3); */
    color: rgba(0, 0, 0, 0.6);
    border-radius: 25px;
    border: 0;
    outline: 0;

    &:focus {
        border: 0;
        outline: 0;
        /* box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.3); */
    }
}

@media screen and (max-width: 991px) {

    .dark-blue-button,
    .dark-purple-button,
    .light-blue-button,
    .light-purple-button {
        width: max-content;
        padding: 0 20px;
    }
}

@media screen and (max-width: 767px) {
    .searchDiv {
        max-width: 100%;
        width: 200vw;
    }
}