.main-view {
    padding-left: 4rem;
}

.email-thumb-dark-blue {
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 1.75rem;
    color: rgb(57, 112, 179);
    background-color: rgba(57, 112, 179, 0.15);
    font-size: 2rem;
    font-weight: 400;
}

.email-thumb-light-blue {
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 1.75rem;
    color: rgb(82, 70, 179);
    background-color: rgba(82, 70, 179, 0.15);
    font-size: 2rem;
    font-weight: 400;
}

.email-thumb-dark-purple {
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 1.75rem;
    color: #a952ff;
    background-color: rgba(169, 82, 255, 0.15);
    font-size: 2rem;
    font-weight: 400;
}

.email-thumb-light-purple {
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 1.75rem;
    color: rgb(140, 82, 255);;
    background-color: rgba(140, 82, 255, 0.15);
    font-size: 2rem;
    font-weight: 400;
}

.table-border-view-table {
    border: 2px dashed #e9ecef;
    border-bottom: none;
    /* padding: 1rem; */
}

.table-border-view-table:last-child {
    border-bottom: 2px dashed #e9ecef;
}

.highlight-selected {
    border-right: 0;
    border: 2px dashed #cdb5f8;
}

.download-icon{
    height: 20px;
}

/* .viewer-email{
    opacity: 0.6;
    transition: 0.2s ease-in-out;
} */

.viewer-email:hover{
    cursor: pointer;
}


.mobile-table{
    gap: 0px;
    border-radius: 10px;
    /* box-shadow: 0px 0px 20px rgb(199, 199, 199); */
    width: 98%;
}

.mobile-box-1, .mobile-box-2{
    align-items: center;
    justify-content: space-around;
    width: 90%;
}

.mobile-box-2 p{
    width: 100%;
    text-align: center;
}

/* .mobile-box-1 img{
    height: 100px;
} */

/* .mobile-name{
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
} */

.mobile-details{
    color: #b3b3b3;
}

.view-wrapper{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #d8d8d8;
    z-index: 999;
}

.cross{
    position: fixed;
    font-size: 52px;
    color: rgb(84, 84, 84);
    top: -10px;
    right: 10px;
}

h4 > span{
    font-size: 12px;
    color: #717171;
}


@media screen and (max-width: 1200px){
    .main-view {
        padding-left: 0rem;
    }

    .viewer-email{
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}