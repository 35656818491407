/* General Styles */
.signup-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.signupPageImg {
    width: 20vw;
}
.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; /* Ensures the loader is centered vertically */
    width: 100%; /* Ensures the loader is centered horizontally */
}

.signup-form-container {
    display: flex;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    border-radius: 10px;
    max-width: 900px;
    width: 100%;
    overflow: hidden;
}

.signup-form {
    padding: 30px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.doculensText{
  color: #6f42c1;
  margin: 0 10px;
  font-weight: 500;
  text-align: center;
}
.doculensLogo{
  width: 6vw;
  margin-bottom: 1vw;
}
.signup-form h2 {
    font-size: 28px;
    font-weight: 600;
    color: #1a202c;
    margin-bottom: 10px;
}

.error-message {
    color: red;
    margin-bottom: 15px;
}

.form-group {
    margin-bottom: 10px;
}

.form-group label {
    display: block;
    font-size: 14px;
    color: #4a5568;
    margin-bottom: 5px;
}

.form-group input {
    width: 100%;
    padding: 12px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 16px;
    outline: none;
}

.form-group input:focus {
    border-color: #6f42c1;
}
.termAndConditionContainer{
    display: flex;
    flex-direction: column;
    margin-top: 1vw;
}
.termsCheckbox{
    margin-right: 0.8vw;
}
.signup-button {
    width: 100%;
    padding: 14px;
    background-color: #6f42c1;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;
        display: flex;
    align-items: center;
    justify-content: center;
}
.error-text{
    color: #D80032;
}
.signup-button:hover {
    background-color: #5a379c;
}

.login-text {
    /* margin-top: 10px; */
}

.login-text a {
    color: #6f42c1;
}

.login-text a:hover {
    text-decoration: underline;
}

.signup-image {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 3vw;
  position: relative;
  padding-left: 2vw;
  background: none;
}

.signup-image::before {
  content: "";
  position: absolute;
  left: 0;
  height: 50%;
  width: 1px;
  background-color: #DFDFDE;
  top: 25%;
}

.name-container {
    display: flex;
    justify-content: space-between;
    gap: 20px
}

.name-input {
    flex: 1;
}
.image-placeholder {
    color: #6f42c1;
    font-size: 24px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .signup-wrapper {
        align-items: flex-start;
        overflow-y: auto;
    }

    .signup-image {
        display: none;
    }

    .signup-form-container {
        overflow-y: scroll;
        flex-direction: column;
        max-height: 100%;
    }

    .doculensLogo {
        width: 20vw;
        margin-bottom: 2vw;
    }

    .termAndConditionContainer {
        margin-top: 3vw;
    }

    .termsCheckbox {
        margin-right: 2vw;
    }

    .signup-form {
        padding: 20px;
    }
    .name-container {
        flex-direction: column;
    }

    .name-input {
        width: 100%;
    }
}
