.navbar{
    z-index: 300;
}

.nav-item{
    cursor: pointer;
}

.logo {
    padding: 0.5rem;
    height: 3.3rem;
    align-items: center;
    display: flex;
    gap: 4vw;
}

.user-logo {
    margin: 0.5rem;
    height: 1.5rem;
    background-color: #ffffff;
    border:#111111 solid 1px 1px 1px 1px ;
}

.profile-name {
    color: #8798ad;
    font-weight: 600;
    font-size: 0.9rem;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
}
.navbar-icons{
    font-size: 20px;
    margin: 0 0.5vw;
    color: #000;
    cursor: pointer;
}
.upgrade-btn{
    margin: 0 1vw;
    background: #b4dc19;
    border-radius: 1vw;
    padding: 0.2vw 0.5vw;
    font-size: 0.8vw;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.upgrade-btn-icon{
    font-size: 0.7vw;
    color: #000;
    margin-right: 0.3vw;
}
.navbar {
    position: fixed;
    width: 100%;
    top: 0;
    padding-top: 0rem;
    padding-bottom: 0rem;
box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    background-color: #fff;
height: 60px;
}

.nav-button {
    padding: 0.5rem 1.5rem;
    height: 2.5rem;
    color: #ffffff;
    font-weight: 500;
    background-color: #8c52ff;
    border: none;
    border-radius: 0.2rem;
    font-size: 0.9rem;
}

.dropdown-menu.show{
    width: max-content;
    left: -100px;
}

a{
    text-decoration: none;
}
@media only screen and (max-width: 992px) {
.upgrade-btn{
    margin: 0 1vw;
    background: #b4dc19;
    border-radius: 1vw;
    padding: 0 0.5vw;
    font-size: 1vw;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.upgrade-btn-icon{
    font-size: 0.7vw;
    color: #000;
    margin-right: 0.3vw;
}
.navbar-icons{
    margin: 0 1vw;
}
}