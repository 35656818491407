.floating-button{
	position:fixed;
	width:80px;
	height:80px;
	bottom:90px;
	right:60px;
	color: #a952ff;
	border-radius:50px;
	border: 0px solid;
    font-size: 1.8em;
    background-color: rgba(169, 82, 255, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
}



.outer-box {
    /* max-width: 1200px; */
    max-height: 4vw;
    width: 100%;
    display: flex;
    /* padding: 15px; */
    /* border: 1px dashed #8c52ff; */
    /* background-color: #8c52ff; */
}

.image-crop-box {
    /* max-height: 500px; */
    max-width: 500px;
    display: flex;
    padding: 15px;
    flex-direction: column;
}

.inner-box {
    /* background-color: rgba(0, 0, 0, 0.1); */
    border-radius: 4px;
    border: 1px solid #8c52ff;
    color: #424242;
    flex: 1 1 auto;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.upload-icon {
    justify-content: center;
    /* color: white; */
    font-size: 1.8rem;
    color: rgb(228, 6, 6);
}

.upload-text {
    font-size: 1rem;
    /* color: white; */
    cursor: default;
}

.upload-error-text {
    font-size: 1.2rem;
    color: rgb(240, 226, 35);
}

@media screen and (max-width: 991px){
    .floating-button{
        width: 60px;
        height: 60px;
        bottom: 50px;
        right: 20px;
    }
    .outer-box{
        width: 95vw;
    }
    .upload-text{
        text-align: center;
    }
}