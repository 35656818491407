.full-length {
    max-height: 100vh;
    min-height: 100vh;
}

::-webkit-scrollbar {
    display: none;
}

.grey-shadow {
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}