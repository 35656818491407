.profile-user-image {
    padding: 0.5rem;
    height: 7rem;
    border: #e9ecef solid 2px;
    border-radius: 1rem;
}

.profile_user_name {
    font-weight: 400;
    font-size: 1.2rem;
    margin-bottom: 0.4rem;
    word-break: break-all;

}

.profile_extra_info {
    font-weight: 500;
    font-size: 0.9rem;
    margin-bottom: 0.2rem;
    color: #8798ad;
}

.profile-heading-icon {
    margin-right: 10px;
}

.value-point-div {
    min-height: 3rem;
    min-width:4rem;
    max-height: 3rem;
    max-width: 4rem;
    border-radius: 1rem;
}

.value-point-icon {
    font-size: 1.1rem;
}


.value-point-heading {
    font-weight: 400;
    font-size: 0.8rem;
    margin-bottom: 0;
    text-transform: uppercase;
    color: #8798ad
}

.value-point-number {
    font-weight: 400;
    font-size: 1.1rem;
    margin-bottom: 0;
}

.overlay-profile-picture {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #e9ecef;
    border-radius: 1rem;
}

.profile-box {
    position: relative;
}
  
.profile-box:hover .overlay-profile-picture {
    opacity: 1;
}
  
.text-profile-picture {
    color: black;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.mobile-row{
    flex-direction: column;
}

@media screen and (max-width: 991px){
    .box-parent{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .my-box{
        /* justify-content: center !important; */
    }
    .details-box{
        gap: 10px;
    }
    .profile_extra_info, .profile_user_name{
        /* width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: capitalize; */
    } 
    .mobile-row{
        flex-direction: row;
        justify-content: space-between;
    }
}