/* General Styles */
.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.loginPageImg{
    width: 20vw;
}
.login-form-container {
  display: flex;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 10px;
  max-width: 1000px;
  width: 100%;
  overflow: hidden;
}
.termAndConditionContainer{
    display: flex;
    flex-direction: column;
}
.login-form {
  padding: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-form h2 {
  font-size: 28px;
  font-weight: 600;
  color: #1a202c;
  margin-bottom: 10px;
}

.signup-text {
  margin-bottom: 20px;
  color: #718096;
  font-size: 14px;
}

.signup-text a {
  color: #6f42c1;
  text-decoration: none;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 14px;
  color: #4a5568;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 16px;
  outline: none;
}

.form-group input:focus {
  border-color: #6f42c1;
}

.forgot-password {
  display: block;
  font-size: 12px;
  color: #6f42c1;
  text-align: right;
  text-decoration: none;
}

.remember-me {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5vw;
  color: #4a5568;
  margin: 0;
}
.rememberMeContainer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1vw;
}
.remember-me input{ width: auto;}
.login-button {
  width: 100%;
  padding: 14px;
  background-color: #6f42c1;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
}

.login-button:hover {
  background-color: #5a379c;
}

.divider {
  text-align: center;
  margin: 20px 0;
  font-size: 14px;
  color: #718096;
}

.social-login {
  display: flex;
  justify-content: center;
  align-items: center;
}

.google-login,
.facebook-login {
  flex: 1;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.google-login {
  margin-right: 10px;
}

.google-login:hover {
  border-color: #ea4335;
}

.facebook-login:hover {
  border-color: #3b5998;
}
.doculensText{
  color: #6f42c1;
  margin: 0 10px;
  font-weight: 500;
  text-align: center;
}
.doculensLogo{
  width: 6vw;
  margin-bottom: 1vw;
}
.login-image {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;  /* Centers the content vertically */
  align-items: center;
  gap: 3vw;
  position: relative;  /* Required for the pseudo-element positioning */
  padding-left: 2vw;  /* Add some padding to move content away from the border */
  background: none;  /* Remove the background gradient */
}

.login-image::before {
  content: "";
  position: absolute;
  left: 0;
  height: 50%;  /* Set the height of the border to 50% of the container's height */
  width: 2px;
  background-color: #DFDFDE;
  top: 25%;  /* Center the border vertically by positioning it at 25% from the top */
}

.image-placeholder {
  color: #6f42c1;
  font-size: 24px;
  font-weight: bold;
}

.password-input-container {
    position: relative;
}
.toggle-link{
  color: #6f42c1;
cursor: pointer;
  font-weight: 500;
}

.password-toggle-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

@media (max-width: 768px) {
.login-image {
display: none;
}
.login-form-container{
  flex-direction: column;
}
    .doculensLogo{
    width: 20vw;
    margin-bottom: 2vw;
    }
    .termAndConditionContainer{
      margin-top: 3vw;
    }
}