body {
  margin: 0;
  font-family: 'Rubik', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #141d3a;
  background-color: white;
}

code {
  font-family: 'Rubik', sans-serif, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.shimmer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  background: linear-gradient(to right, #f6f7f8 8%, #eaeaea 18%, #f6f7f8 33%);
  background-size: 1000px 100%;
  animation: shimmer 1.5s infinite;
  margin: 10px 0;
  padding: 0 1vw;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.shimmer-item {
  margin: 0.4vw 0;
  background: #eaeaea;
  border-radius: 4px;
}

.shimmer-thumbnail {
  width: 120px;
  height: 60px;
}
.shimmer-text-lastCol{
  width: 15%;
  height: 30px;
}
.shimmer-text {
  width: 80%;
  height: 30px;
}

.shimmer-button {
  width: 10%;
  height: 30px;
}

.shimmer-views-thumbnail {
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
}
.shimmer-views-text {
  width: 50%;
  height: 30px;
}

.shimmer-views-button {
  width: 10%;
  height: 30px;
}

@media screen and (max-width: 991px) {

.shimmer {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  background: linear-gradient(to right, #f6f7f8 8%, #eaeaea 18%, #f6f7f8 33%);
  background-size: 1000px 100%;
  animation: shimmer 1.5s infinite;
  margin: 10px 0;
  padding: 1vw;
  border-radius: 10px;
}
.shimmer-item {
    display: flex;
    flex-direction: row;
    background: transparent;
    gap: 1vw;
    align-items: center;
    width: 100%;
}
.shimmer-thumbnail {
  width: 20%;
  height: 60px;
}
.shimmer-text {
  width: 70%;
  height: 30px;
}
.shimmer-button, .shimmer-text, .shimmer-thumbnail {
  background: #eaeaea;

}
}