.currency-toggle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f0f0f0;
  padding: 5px;
  border-radius: 30px;
  position: relative;
  width: 180px;
}

.currency-option {
  font-size: 16px;
  padding: 5px 20px;
  z-index: 2; /* Ensure the labels are above the toggle */
  cursor: pointer;
  color: #888;
  font-weight: 500;
}

.currency-option.active {
  font-weight: bold;
  color: #fff;
}
.disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
}

.toggle-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  border-radius: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggle-slider {
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: #000;
  border-radius: 30px;
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1); /* Updated for smoother motion */
}
.payableText{
    font-size: 1vw;
}
.toggle-left {
  left: 0;
}

.toggle-right {
  right: 0;
}

.card {
    border: 1px solid #f0f0f0;
    transition: transform 0.2s ease-in-out;
}

.card:hover {
    transform: scale(1.05);
}

.shadow-sm {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.rounded-lg {
    border-radius: 12px;
}

.pricing {
    font-size: 2rem;
    color: #333;
}

.btn-outline-primary {
    background-color: #fff;
    border-color: #a952ff;
    color: #000;
}

.btn-outline-primary:hover {
        border-color: #a952ff;
    background-color: #a952ff;
    color: #fff;
}
.subscribed-btn{
        border-color: #a952ff;
    background-color: #a952ff;
    color: #fff;
}
.subscribed-btn:hover{
            border-color: #a952ff;
    background-color: #a952ff;
    color: #fff;
}
.subscribed-btn img{
    width: 0.8vw;
    height: auto;
}
ul {
    text-align: left;
    padding: 0;
}

ul li {
    margin-bottom: 8px;
    font-size: 1rem;
}

/* Shimmer effect styling */
.shimmer-pricing {
    position: relative;
    overflow: hidden;
    background-color: #f6f7f8;
    background-image: linear-gradient(90deg, #f6f7f8 25%, #e0e0e0 50%, #f6f7f8 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

.shimmer-wrapper .shimmer-pricing {
    height: 20px;
}
.shimmer-featurelist{
    height: 5vw !important;
    background: #fff;
}
.shimmer-pricing.h2 {
    height: 30px;
    margin-bottom: 20px;
}

.shimmer-pricing-button {
    border: none;
    height: 3vw !important;
    width: 8vw;
    background-image: linear-gradient(90deg, #f6f7f8 25%, #e0e0e0 50%, #f6f7f8 75%);
}

.featureImg {
    width: 1vw;
    height: auto;
    object-fit: contain;
    vertical-align: middle;
}

.feature-text {
    vertical-align: middle;
}
.confirmPlanButton{
    background: #a952ff;
    color: #fff;
    border: none;
}
.confirmPlanButton:hover {
    background: #a952ff;
    color: #fff;
    border: none;
}

.confirmPlanButton:disabled {
    background: #fff;
    border: 1px solid #000;
    color: #000;
    cursor: not-allowed;
}

.cancelPlanButton{
    color: #000;
    border: 1px solid #000;
    background: transparent;
}

.cancelPlanButton:hover {
    background-color: #fff;
    color: #000;
}

@media only screen and (max-width: 992px) {
.featureImg {
    width: 4.5vw;
}
.payableText{
    font-size: 5vw;
}
}