.purple-button-document {
    color: #993bb3;
    background-color: #e9d8ee;
    width: 6rem;
    height: 2rem;
    border-radius: 1rem;
    font-weight: 600;
    border: 0px;
    font-size: 0.9rem;
    margin: 5px;
    display: block;
}

.green-button-document {
    color: #4d9369;
    background-color: #d4e6dd;
    width: 6rem;
    height: 2rem;
    border-radius: 1rem;
    font-weight: 600;
    border: 0px;
    font-size: 0.9rem;
    margin: 5px;
}

.blue-button-document {
    color: #619cc3;
    background-color: #d3ebfb;
    width: 6rem;
    height: 2rem;
    border-radius: 1rem;
    font-weight: 600;
    border: 0px;
    font-size: 0.9rem;
    margin: 5px;
}

.red-button-document {
    color: #b33b3b;
    background-color: #eed8d8;
    width: 6rem;
    height: 2rem;
    border-radius: 1rem;
    font-weight: 600;
    border: 0px;
    font-size: 0.9rem;
    margin: 5px;
}

.white-button-document {
    color: #fff;
    background-color: #8c52ff;
    width: 6rem;
    height: 2rem;
    border-radius: 4px;
    border: 0;
    font-weight: 400;
    font-size: 0.9rem;
    margin: 5px;
}

.document-heading-icons {
    min-width: 20rem;
    width: 40rem;
}

.box{
    border-radius: 15px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
}

.mobile-table{
    gap: 30px;
    border-radius: 10px;
    /* box-shadow: 0px 0px 20px rgb(199, 199, 199); */
}

.mobile-box-1{
    display: flex;
    justify-self: center;
    align-items: center;
}

.mobile-box-1 p{
    margin: 0;
}

.details-box{
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
    flex-direction: column;
    gap: 2vw;
}

.extra-value{
    gap: 15px;
}

.extras-wrapper{
    display: flex;
    gap: 10px;
}

.button-wrapper{
    gap: 20px;
}
.button-wrapper-mobile{
    gap: 20px;
    margin-left: -10vw;
}

@media screen and (max-width: 991px) {
    .value-point-description{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 10px;
    }
    .details-box{
        justify-content: center;
    }
}

