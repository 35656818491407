.full-page {
    min-height: 100vh;
}

.nav-down-border {
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

.z-top {
    z-index: 1;
}

.react-pdf__Document:hover .page-controls {
    opacity: 1;
}

@media (hover: hover) and (pointer: fine) {
    .page-controls {
        opacity: 1;
    }
}

.page-controls {
    color: white;
    position: absolute;
    bottom: 5%;
    left: 50%;
    background: #52a1ff;
    transform: translateX(-50%);
    transition: opacity ease-in-out 0.2s;
    /* box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%); */
    border-radius: 4px;
}

.page-controls button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.page-controls button {
    width: 44px;
    height: 44px;
    background: #52a1ff;
    border: 0;
    font: inherit;
    font-size: 1em;
    font-weight: bolder;
    border-radius: 4px;
    color:white;
}

.page-controls button:hover:enabled {
    background: #3970b3;   
}

.page-controls button:disabled {
    color: #3970b3;
    
}

.page-controls span {
    font: inherit;
    font-size: 1em;
    font-weight: bold;
    padding: 0 0.5em;
}

.page-controls button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.enter-email-heading {
    font-weight: 600;

}

.label {
    font-size: 0.8rem;
}

.pdf-reader-button {
    border: solid 1px #a952ff;
    color: #fff;
    background-color: #303133;
    /* width: 8rem; */
    height: 3rem;
    border-radius: 3px;
    font-weight: 600;
    font-size: 0.9rem;
    margin: 5px;
    padding: 12px 20px;
}

.sidebar {
    min-height: 100vh;
    max-width: 8rem;
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 5rem;
    background-color: #303133;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    color: #fff;
}

.pdf-reader-thumbnail {
    border: solid 1px #e9ecef;
    border-radius: 5px;
    margin-top: 1rem;
    padding: 0.1rem;
    width: 6rem;
    cursor: pointer;
}

.pdf-reader-thumbnail-selected {
    border: #e9ecef solid 2px;
}

.top-margin-large {
    margin-top: 7rem;
}

.popover-name {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
}

.popover-email {
    margin: 0;
    font-weight: 400;
    color: '#8798ad';
}

.popover-user-image-box {
    border: solid 2px #52a1ff;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    align-items: center;
    font-size: 3rem;
    color: #52a1ff;
    margin: 0.5rem
}

.popover-button {
    border: solid 2px #52a1ff;
    color: #52a1ff;
    background-color: white;
    /* width: 8rem; */
    height: 2rem;
    border-radius: 3px;
    font-weight: 600;
    font-size: 0.8rem;
    margin: 5px;
    padding: 5px 10px;
}
@media only screen and (min-width: 992px) {
    canvas.react-pdf__Page__canvas {
        width: 90% !important;
        height: auto !important;
        margin: 0 auto ;
    }
}

@media only screen and (max-width: 992px) {
    canvas {
        width: 100% !important;
        height: auto !important;
        margin: auto !important;
    }
}