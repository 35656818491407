.profile-wrapper {
    width: 100vw;
    height: 100vh;
}

.profile-content-wrapper {
    /* margin-left: 8vw; */
    padding-top: 4rem;
    gap: 20px;
}

.profile-column-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: max-content;
    gap: 10px;
}

.profile-block {
    margin-left: -6.3vw;
    margin-top: -2vw;
    width: 45vw;
    border-radius: 8px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    padding: 20px;
}

.profile-input {
    width: auto;
}

.btn:focus {
    outline: none;
    box-shadow: none;
}

.profile-row {
    gap: 0px;
    align-items: flex-start;
}

.profile-page-image {
    height: 200px;
    width: 200px;
    padding: 0.5rem;
    border: #e9ecef solid 2px;
    border-radius: 1rem;
}

.profile-page-button {
    width: max-content;
    padding: 0 40px;
}

.edit-section {
    gap: 10px;
}

.edit-section p {
    margin: 0;
}

.edit {
    font-size: 12px;
    color: cadetblue;
    cursor: pointer;
}

.profile-row>div {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-tabs .nav-link.active {
  color: #fff; /* Text color of the active tab */
  background-color: #007bff; /* Background color of the active tab */
  border-color: #007bff; /* Border color of the active tab */
}

.nav-tabs .nav-link {
  color: #007bff; /* Text color of the inactive tabs */
}

.nav-tabs .nav-link:hover {
  color: #0056b3; /* Text color of the inactive tabs on hover */
}
.custom-modal .modal-content {
  background-color: #f8f9fa; /* Light background */
  border-radius: 10px;       /* Rounded corners */
  border: none;              /* Remove border */
  padding: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Soft shadow */
}

.custom-modal .modal-header {
  border-bottom: none; /* Remove header border */
}

.custom-modal .modal-title {
  color: #343a40; /* Darker title color */
}

.custom-modal .modal-body {
  padding-top: 0;
  padding-bottom: 0;
}

.custom-modal .form-label {
  color: #495057; /* Dark gray label text */
}

.custom-modal .form-control {
  border: 1px solid #ced4da;
  border-radius: 8px;
  padding: 10px;
}

.custom-modal .form-control:focus {
  border-color: #a952ff; /* Custom purple border when focused */
  box-shadow: none;      /* Remove default shadow */
}

.custom-modal .modal-footer {
  border-top: none; /* Remove footer border */
  padding: 0;
}

.custom-modal .btn-cancel {
  background-color: #6c757d; /* Gray cancel button */
  color: white;
  border-radius: 6px;
  padding: 8px 16px;
  border: none;
  transition: background-color 0.3s ease;
}

.custom-modal .btn-cancel:hover {
  background-color: #5a6268; /* Darker gray on hover */
}

.custom-modal .btn-invite {
  background-color: #a952ff; /* Custom purple invite button */
  color: white;
  border-radius: 6px;
  padding: 8px 16px;
  border: none;
  transition: background-color 0.3s ease;
}
.loader-container-inviteUser {
      display: flex;
    align-items: center;
    justify-content: center;
      background-color: #a952ff; /* Custom purple invite button */
  color: white;
  border-radius: 6px;
  padding: 8px 16px;
  border: none;
  transition: background-color 0.3s ease;
}

.custom-modal .btn-invite:hover {
  background-color: #922fe0; /* Darker purple on hover */
}

.inviteUserContainer{
    margin: 1vw 0;
}
.PhoneInputInput{
    border: 1px solid #ced4da;
    border-radius: 8px;
    padding: 10px;
}
.copyButton, .inviteButton{
  background-color: #6f42c1;
  color: #fff;
}
.closeButton{
  border: 1px solid rgb(216, 216, 216);
  border-radius: 6px;
  font-size: 16px;
  background-color: #fff; 
  color:#000
}

.closeButton:hover {
  border: 1px solid rgb(216, 216, 216);
  border-radius: 6px;
  font-size: 16px;
  background-color: #fff; 
  color:#000;
}
.copyButton:hover, .inviteButton:hover{
  background-color: #5a379c;
    color: #fff;
}

@media only screen and (max-width: 992px) {
 .profile-block {
width: 100%; 
}
}