.footer {
    margin-top: 1.5rem;
    position: fixed;
    bottom: 0;
    width: 100%;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    border-top: #eeeeee solid 1px;
    background-color: white;
}

.footer-text {
    padding: 0.4rem;
    font-weight: 600;
    margin: 0;
    color: #8798ad;
    font-weight: 400;
    font-size: 0.9rem;
}