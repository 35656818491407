.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f9;
}

.forgot-password-content {
    display: flex;
    max-width: 1200px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.forgot-password-form-section {
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.forgot-password-form-section h2 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: left;
    color: #333;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
}

.form-group input {
    width: 100%;
    padding: 12px 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease;
}

.form-group input:focus {
    border-color: #007bff;
}

.password-input-container {
    position: relative;
}

.password-toggle-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
}

.error-text {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

.submit-button {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    background-color: #6f42c1;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #5a379c;
        color: white;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.forgot-password-image-section {
    flex: 1;
    background-color: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-left: 2vw;
}

.forgot-password-image-section::before {
    content: "";
    position: absolute;
    left: 0;
    height: 50%;
    width: 1px;
    background-color: #DFDFDE;
    top: 25%;
}

.doculensLogo{
  width: 6vw;
  margin-bottom: 1vw;
}
.forgot-password-image-section img {
    width: 80%;
    max-width: 400px;
    height: auto;
}

@media screen and (max-width: 767px) {
    .forgot-password-image-section {
        display: none;
    }
        .doculensLogo {
        width: 20vw; /* Adjust logo size */
        margin-bottom: 2vw; /* Space below the logo */
    }
}
