/* General Styles */
.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.login-form-container {
  display: flex;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 10px;
  max-width: 900px;
  width: 100%;
  overflow: hidden;
}

.login-form {
  padding: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-form h2 {
  font-size: 28px;
  font-weight: 600;
  color: #1a202c;
  margin-bottom: 10px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 16px;
  outline: none;
  padding-right: 40px; /* Leave space for the icon */
}

.form-group input:focus {
  border-color: #6f42c1; /* Use the same purple color */
}

.password-input-container {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.rememberMeContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1vw;
}

.remember-me {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5vw;
  color: #4a5568;
  margin: 0;
}

.forgot-password {
  font-size: 12px;
  color: #6f42c1;
  text-align: right;
  text-decoration: none;
}

.login-button {
  width: 100%;
  padding: 14px;
  background-color: #6f42c1; /* Button matches the LOGIN button color */
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
}

.login-button:hover {
  background-color: #5a379c;
}

.login-image {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 3vw;
  position: relative;
  padding-left: 2vw;
  background: none;
}

.login-image::before {
  content: "";
  position: absolute;
  left: 0;
  height: 50%;
  width: 1px;
  background-color: #DFDFDE;
  top: 25%;
}
.loginPageImg {
  width: 20vw;
}

@media (max-width: 768px) {
  .login-form{
    padding: 20px;
  }
  .login-image {
    display: none;
  }
  .doculensLogo {
        width: 20vw;
        margin-bottom: 4vw;
    }

  .login-form-container {
    flex-direction: column;
  }
}
