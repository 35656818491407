.circle-div {
    border-radius: 50%;
}

.light-blue-color-background {
    color: rgb(82, 70, 179);
    background: rgba(82, 70, 179, 0.15)
}

.dark-blue-color-background {
    color: rgb(57, 112, 179);
    background-color: rgba(57, 112, 179, 0.15);
}

.light-purple-color-background {
    color: #a952ff;
    background-color: rgba(169, 82, 255, 0.15);
}

.dark-purple-color-background {
    color: rgb(140, 82, 255);
    background-color: rgba(140, 82, 255, 0.15);
}

.light-blue-color {
    color: rgb(82, 70, 179);
}

.dark-blue-color {
    color: #a952ff;
}

.black-color {
    color: #000;
}

.light-purple-color {
    color: rgb(100, 167, 227)
}

.division {
    border-bottom: #f5f8fa solid 1px;
}

.right-border {
    border-right: #f5f8fa solid 1px;
}

.grey-background {
    background-color: #f5f8fa;
}

.white-background {
    background-color: #ffffff;
}